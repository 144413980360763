import onUpdateCartCounter from './update-cart-counter';
import onMiniCartUpdate from './mini-cart-updater';

export default function (productId, modificationId, count)
{
    return new Promise((resolve) => {
        $.request('onShopAddProductToCart', {
            method: 'POST',
            loading: $.oc.stripeLoadIndicator,
            data: {
                product_id: productId,
                modification_id: modificationId,
                count
            }
        }).then((response) => {
            onUpdateCartCounter(response);
            onMiniCartUpdate(response);
            if (typeof window.EventBus !== undefined) {
                window.EventBus.$emit('onAddProductToCart', {
                    productId,
                    modificationId,
                    count
                });
            }
            resolve();
        });
    });
}