import {Swiper, Pagination, Navigation, Grid, Autoplay} from "swiper";

new Swiper(".mainSwiper", {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
});

new Swiper(".partnersSwiper", {
    modules: [Pagination, Navigation, Grid],
    slidesPerView: 5,
    spaceBetween: 0,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".partners-swiper-button-next",
        prevEl: ".partners-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
        },
        420: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 5,
        },
    },
});

new Swiper(".newsSwiper", {
    modules: [Pagination, Navigation, Grid, Autoplay],
    slidesPerView: 4,
    spaceBetween: 20,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".news-swiper-button-next",
        prevEl: ".news-swiper-button-prev",
    },
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1500: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    },
});