import $ from 'jquery';

$(function () {
    $('.app-scroll-to-block').on('click', function (e) {
        e.preventDefault();

        $("html, body").animate({
            scrollTop: $($(this).data('el')).offset().top
        }, 1000);
    });
});