import createOrderFromProduct from "../services/createOrderFromProduct";

export default function () {
    let $createOrderModal = new Modal(document.querySelector('#createOrderFromProductModal'));
    let $successModal = new Modal(document.querySelector('#createOrderFromProductModalSuccess'));

    $('.app-show-create-order-from-product-modal').on('click', function (e) {
        e.preventDefault();
        $createOrderModal.show();
    });

    $('.app-shop-create-order-from-product').on('submit', function (e) {
        e.preventDefault();

        const $scope = $(this);
        $scope.find('.app-create-order-from-product-flash').html('');

        createOrderFromProduct($scope.find('input[name=product_id]').val(), {
            name:   $scope.find('input[name=user_name]').val(),
            phone:  $scope.find('input[name=user_phone]').val(),
            email: $scope.find('input[name=user_email]').val(),
            comment: $scope.find('input[name=user_comment]').val()
        }).then(() => {
            $createOrderModal.hide();
            $successModal.show();
        });
    });
}